import React from 'react'

import Layout from '../layouts'
import SEO from '../components/seo'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { Section, Image } from '@sites/shared-ui'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  promocard: {
    maxWidth: 420,
    width: '100%',
    padding: 18,
  },
}))

const IndexPage = ({ data, location }) => {
  const classes = useStyles()
  return (
    <Layout header="false" footer="false" location={location}>
      <SEO title="Home" />
      <Section
        fullWidth
        disablePadding
        style={{
          display: 'flex',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div style={{ textAlign: 'center' }}>
          <Image
            src={data.banner.childImageSharp.full.src}
            alt="Lotus Group"
            style={{ objectFit: 'cover' }}
          />
          <Typography variant="h2">LOTUS GROUP</Typography>
          <Typography variant="h3" gutterBottom>
            We're coming soon.
          </Typography>
          <Typography variant="h4">
            Our website is under construction.
          </Typography>
          <Typography variant="h4" gutterBottom>
            We'll be here soon with our new awesome site.
          </Typography>
          <Button
            variant="contained"
            size="large"
            component="a"
            href="https://api.whatsapp.com/send?phone=919265765215&text=Hello%2C%20I%20want%20to%20know%20more%20about%20your%20company"
            disableElevation={true}
            style={{
              backgroundColor: '#4FCE5D',
              color: '#fff',
              fontSize: 20,
              borderRadius: 20,
              textTransform: 'capitalize',
            }}
            startIcon={<WhatsAppIcon />}
          >
            Contact Us
          </Button>
        </div>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query ComingSoon {
    banner: file(relativePath: { eq: "lotus_logo.png" }) {
      childImageSharp {
        full: fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default IndexPage
